<template>
    <div class="slider-recurso">
        <slick ref="slick" :options="slickOptions">
            <div class="slide" :key="key" v-for="(n,key) in data">
                <img :src="n.enlace" :alt="n.pie" @click="setslide(key)">
            </div>
        </slick>

        <div class="slider-popup" v-if="showslider">
            <div class="contenedor-slider" :show="opacityslider">
                <a class="close" @click="showslider = false">✕</a>
                <slick ref="slickpopup" :options="slickpopupOptions">
                    <div class="slide" :key="key" v-for="(n,key) in data">
                        <img :src="n.enlace" :alt="n.pie">
                    </div>
                </slick>
            </div>
        </div>

    </div>
</template>

<script>

    import Slick from 'vue-slick';

    import 'slick-carousel/slick/slick.css';

    export default {
        name: "slider-recurso",
        props: ['data'],
        data() {
            return {
                showslider: false,
                opacityslider: true,
                slickOptions: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    centerMode: false,
                    dots: false,
                    arrows: true,
                    adaptiveHeight: false
                },
                slickpopupOptions: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: false,
                    dots: false,
                    arrows: true,
                    adaptiveHeight: false
                },
            }
        },
        components: { Slick },
        methods: {
            setslide(pos) {
                this.showslider = true;
                setTimeout(() => {
                    this.$refs.slickpopup.goTo(pos);
                    this.opacityslider = true;
                }, 200)
            }
        }
    }
</script>



<style lang="scss" scoped>
    
    @import '@/assets/css/colors.scss';

    .slider-recurso {
        padding: 0 40px;
        margin-top: 40px;
        margin-bottom: 40px;

        .slide {
            padding: 0 10px;

            img {
                max-width: 100%;
                cursor: pointer;
            }
        }

        .slick-prev {
            left: -40px;
        }

        .slick-next {
            right: -40px;
        }
    }

    .slider-popup {
        position: fixed;
        left: 0;
        top: 0;
        background: rgba(0, 0, 0, 0.4);
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 20;

        .contenedor-slider {
            max-width: 80vw;
            position: relative;
            opacity: 0;
            transition: 0.7s;

            &[show="true"] {
                opacity: 1;
            }

            .close {
                position: absolute;
                right: 0;
                top: -70px;
                width: 50px;
                height: 50px;
                line-height: 50px;
                text-align: center;
                display: inline-block;
                border-radius: 50%;
                color: white;
                background: $yellow;
            }

            img {
                max-width: 78vw;
                max-height: 78vh;
                margin: 0 auto;
            }
        }
    }
</style>